import React, { useEffect, useRef, useState, useCallback } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import classNames from 'classnames'
import { gsap } from 'gsap'

type Props = {
  blok: SbBlokData & ISbSectionLogosV2
  hasPadding?: boolean
}
const TARGET_LOGO_COUNT = 24
const ANIMATION_DURATION = 60

const LogoItem = ({ logo }: { logo: ISbAsset }) => {
  return (
    <div className="logo-item bottom-0 left-full top-0 w-52 shrink-0 rounded-xl border border-v2-foreground-static-tertiary bg-white px-10 py-8">
      <BaseImageV2
        src={logo.filename}
        alt={logo.alt}
        breakPointsWidthMap={{ 0: 128 }}
        lazy={false}
        className="block h-12 w-full [&_img]:size-full"
      />
    </div>
  )
}

const SectionLogosV2 = ({ blok, hasPadding = true }: Props) => {
  const [isReady, setIsReady] = useState(false)

  const logoBandContainer = useRef<HTMLDivElement>(null)
  const logoBandRef1 = useRef<HTMLDivElement>(null)
  const logoBandRef2 = useRef<HTMLDivElement>(null)
  const tweenRef1 = useRef<GSAPTween | null>(null)
  const tweenRef2 = useRef<GSAPTween | null>(null)

  // Extend the logos array to ensure there are enough logos to keep the animation going
  const extendedLogos = () => {
    const _logos = []

    while (_logos.length < TARGET_LOGO_COUNT) {
      _logos.push(...blok.logos)
    }

    _logos.length = TARGET_LOGO_COUNT

    return _logos
  }

  const animate = useCallback(() => {
    const initialDuration = 0.01

    const containerWidth = logoBandContainer.current?.offsetWidth || 0
    const bandWidth = logoBandRef1.current?.scrollWidth || 0
    const leftEnd = -(bandWidth + containerWidth)

    tweenRef1.current = gsap.fromTo(
      logoBandRef1.current,
      { x: 0 },
      {
        x: leftEnd,
        duration: initialDuration,
        ease: 'linear',
        data: { isFirstRun: true },
        onUpdate: () => {
          const currentX = Number(gsap.getProperty(logoBandRef1.current, 'x'))

          // Restart the animation when the first band is about to reach the end
          if (currentX <= leftEnd + containerWidth && !tweenRef2.current?.isActive()) {
            tweenRef2.current?.restart()

            // Set the duration to the actual animation duration after the first run
            if (tweenRef1.current?.data.isFirstRun) {
              tweenRef2.current?.duration(ANIMATION_DURATION)
              tweenRef2.current?.progress(0.5)
              tweenRef1.current.data.isFirstRun = false
            }
          }
        },
      },
    )

    tweenRef2.current = gsap.fromTo(
      logoBandRef2.current,
      { x: 0 },
      {
        x: leftEnd,
        duration: initialDuration,
        ease: 'linear',
        onUpdate: () => {
          const currentX = Number(gsap.getProperty(logoBandRef2.current, 'x'))

          // Restart the animation when the second band is about to reach the end
          if (currentX <= leftEnd + containerWidth && !tweenRef1.current?.isActive()) {
            tweenRef1.current?.duration(ANIMATION_DURATION)
            tweenRef1.current?.restart()
          }
        },
      },
    )

    tweenRef2.current?.pause()
  }, [])

  const pauseAnimation = () => {
    if (!isReady) {
      return
    }

    tweenRef1.current?.pause()
    tweenRef2.current?.pause()
  }

  const resumeAnimation = () => {
    tweenRef1.current?.resume()
    tweenRef2.current?.resume()
  }

  useEffect(() => {
    animate()

    // reset animation on resize
    const resizeHandler = () => {
      tweenRef1.current?.kill()
      tweenRef2.current?.kill()
      animate()
    }

    // Wait for the initial animation to finish before showing the logos
    setTimeout(() => {
      setIsReady(true)
    }, 300)

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
      tweenRef1.current?.kill()
      tweenRef2.current?.kill()
    }
  }, [animate])

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('container-v2 mx-auto flex w-full flex-col gap-8', { 'py-16 lg:py-24': hasPadding })}
    >
      <ItemSectionHeadV2
        blok={
          blok.head?.[0] || {
            kicker: blok.title,
          }
        }
      />
      <div
        ref={logoBandContainer}
        className={classNames(
          'relative flex h-28 w-full flex-nowrap gap-3 overflow-hidden transition-opacity duration-500 before:absolute before:bottom-0 before:left-0 before:top-0 before:z-10 before:w-24 before:bg-gradient-to-r before:from-white after:absolute after:bottom-0 after:right-0 after:top-0 after:z-10 after:w-24 after:bg-gradient-to-l after:from-white',
          {
            'opacity-0': !isReady,
          },
        )}
        onMouseEnter={pauseAnimation}
        onMouseLeave={resumeAnimation}
      >
        <div ref={logoBandRef1} className="absolute left-full top-0 flex h-full w-max flex-nowrap gap-3 bg-white pr-2.5">
          {extendedLogos()
            .slice(0, TARGET_LOGO_COUNT / 2)
            .map(({ image }, index) => (
              <LogoItem key={index} logo={image} />
            ))}
        </div>

        <div ref={logoBandRef2} className="absolute left-full top-0 flex h-full w-max flex-nowrap gap-3 bg-white pr-2.5">
          {extendedLogos()
            .slice(TARGET_LOGO_COUNT / 2)
            .map(({ image }, index) => (
              <LogoItem key={index} logo={image} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default SectionLogosV2
